import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";
import SEO from "../components/seo";
import { LINKS } from "../constants/links";
import AppButton from "../components/common/button";
import AppPageHeader from "../components/common/texts/appPageHeader";
import AppLeadingText from "../components/common/texts/appLeadingText";
import { AppContainer } from "../components/common/appContainer";

const StyledButtonWrapper = styled.div`
	text-align: center;
`;

const ContactUsSend = () => {
	const { t } = useTranslation();
	return (
		<>
			<SEO title={t("rateUsSend.title")} />
			<AppContainer>
				<AppPageHeader>{t("rateUsSend.header")}</AppPageHeader>
				<AppLeadingText>{t("rateUsSend.description")}</AppLeadingText>
				<StyledButtonWrapper>
					<AppButton as={Link} to={LINKS.HOMEPAGE}>
						{t("rateUsSend.homepageButton")}
					</AppButton>
				</StyledButtonWrapper>
			</AppContainer>
		</>
	);
};

export default ContactUsSend;
